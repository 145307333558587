.react-calendar-heatmap .dark-0 {
  fill: #2d333b;
}
.react-calendar-heatmap .dark-1 {
  fill: #0e4429;
}
.react-calendar-heatmap .dark-2 {
  fill: #006d32;
}
.react-calendar-heatmap .dark-3 {
  fill: #26a641;
}
.react-calendar-heatmap .dark-4 {
  fill: #39d353;
}

.react-calendar-heatmap .light-0 {
  fill: #ebedf0;
}
.react-calendar-heatmap .light-1 {
  fill: #9be9a8;
}
.react-calendar-heatmap .light-2 {
  fill: #40c463;
}
.react-calendar-heatmap .light-3 {
  fill: #30a14e;
}
.react-calendar-heatmap .light-4 {
  fill: #216e39;
}
